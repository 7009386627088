import { saveUserSettings } from "features/Account/redux/userSettings/userSettingsMiddleware";
import Player from "video.js/dist/types/player";
import {
  ClosedCaptionsAddFailure,
  ClosedCaptionsAddSuccess,
  ClosedCaptionsTranslateFailure,
} from "../../../../../Application/services/realTimeNotification/events/libraryEvents";
import { translate } from "../../../../services/assetsService";
import { AutoTranslatePayload, ClosedCaptions } from "../../../../types";
import utils from "./languageUtils";

const SHOWING_MODE = "showing";
const ACTIVE_CLASS = "active";
const SUBS_CAPTIONS_BUTTON = "SubsCapsButton";
export const CLOSED_CAPTION_STORAGE_KEY = "ccLanguage";
export const captionsAddSuccessRtn = ClosedCaptionsAddSuccess;
export const captionsFailureRtn = [ClosedCaptionsAddFailure, ClosedCaptionsTranslateFailure];

export const getActiveTextTracks = (textTracks?: TextTrackList) => {
  if (!textTracks) return;
  for (let i = 0; i < textTracks.length; i++) {//NOSONAR
    const track = textTracks[i];
    if (track.mode === SHOWING_MODE) {
      return track;
    }
  }
};

export const setTrackActive = (player: Player, id: string) => {
  const tracks = (player as any)?.textTracks() || [];

  for (let i = 0; i < tracks.length; i++) {//NOSONAR
    const track = tracks[i];
    if (track.language === id) {
      track.mode = "showing";
      return;
    }
  }
};

export const mapToTextTrackOptions = (caption: ClosedCaptions, mode?: any): any => ({
  id: caption.languageId.toString(),
  src: caption.url,
  kind: "captions",
  label: utils.getLanguageByCode(caption.isoCode)?.name,
  srclang: caption.isoCode,
  mode: mode,
});

export const subscribeOnSelectedLanguageChange = (
  player: Player,
  id: number,
  onSelectedLanguage?: (language: string | null) => void,
) => {
  const videoTrackList = (player as any).textTracks();
  videoTrackList.addEventListener("change", () => startTranslate(player, id, onSelectedLanguage));
};

export const startTranslate = (player: Player, id: number, onSelectedLanguage?: (language: string | null) => void) => {
  const captionButton = (player as any).controlBar.getChild(SUBS_CAPTIONS_BUTTON);
  captionButton?.removeClass(ACTIVE_CLASS);
  const activeTrack = getActiveTextTracks((player as any).textTracks());

  if (activeTrack) {
    saveUserSettings(CLOSED_CAPTION_STORAGE_KEY, activeTrack.language);
    onSelectedLanguage && onSelectedLanguage(activeTrack.language);
    captionButton?.addClass(ACTIVE_CLASS);
    if (!(activeTrack as any).src) {
      activeTrack.addCue(getTranslatingCue(player));
      (async () => {
        await translate(id, activeTrack.id);
      })();
    }
  } else {
    onSelectedLanguage && onSelectedLanguage(null);
    localStorage.removeItem(CLOSED_CAPTION_STORAGE_KEY);
  }
};

export const setCaptionButtonActive = (player?: Player) =>
  (player as any)?.controlBar?.getChild(SUBS_CAPTIONS_BUTTON)?.addClass(ACTIVE_CLASS);

export const handleChangeTextTrack = (payload: AutoTranslatePayload, player?: Player) => {
  const activeTrack = getActiveTextTracks((player as any)?.remoteTextTracks());

  if (activeTrack && Number(activeTrack.id) === payload.languageId) {
    activeTrack.mode = "disabled";
    activeTrack.removeCue(activeTrack.cues![0]);
    (activeTrack as any).src = payload.url;
    activeTrack.mode = "showing";
  }
};

const getTranslatingCue = (player: Player) => {
  const start = player.currentTime()!;
  let end = start + player.remainingTime();
  end = !isNaN(end) && end !== 0 ? end : 0.001;
  const Cue = window.VTTCue || window.TextTrackCue; // IE, Edge support
  return new Cue(start, end, "Translating...");
};
