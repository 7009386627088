import axios from "axios";
import { UserSettings } from "../types";

const settingsUrl = "api/client/user/settings";

export async function fetchUserSettings() {
  const resp = await axios.get<{ settings: UserSettings }>(settingsUrl);
  return resp?.data?.settings;
}

export function updateUserSettings(settingsDelta: Partial<UserSettings>) {
  return axios.put(settingsUrl, {
    settings: settingsDelta,
  });
}
