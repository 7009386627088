import classNames from "classnames/bind";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { AccountMenu } from "../../../Account/components/AccountMenu/AccountMenu";
import { Profile } from "../../../Account/components/Profile/Profile";
import addApplicationInsights from "../../../Auth/config/addApplicationInsights";
import { AppSearch } from "../../../Discover/components/AppSearch/AppSearch";
import { DiscoverPageRoutes } from "../../../Discover/components/DiscoverPageRoutes/DiscoverPageRoutes";
import { GroupPageRoutes } from "../../../Groups/components/GroupPageRoutes/GroupPageRoutes";
import { HomePage } from "../../../Home/components/HomePage/HomePage";
import { AppHeader } from "../AppHeader/AppHeader";
import { AppLanguageSelector } from "../AppLanguageSelector/AppLanguageSelector";
import { BreakpointContextWrapper } from "../context/BreakpointContext";
import NotificationProvider from "../NotificationProvider/NotificationProvider";
import { PrivacyPolicyBanner } from "../PrivacyPolicyBanner/PrivacyPolicyBanner";

import {
  selectPrivacyPolicyViewed,
  selectSettingsLoaded,
} from "features/Account/redux/userSettings/userSettingsSelectors";
import LdProvider from "../../../../tools/ld/LdProvider";
import { userInTeams } from "../../../../tools/teams/teamsService";
import { req as reqAccount } from "../../../Account/redux/account/accountSlice";
import { req as reqProfile } from "../../../Account/redux/profile/profileSlice";
import { req as reqUserSettings } from "../../../Account/redux/userSettings/userSettingsSlice";
import { selectBannerOpen, setOpen } from "../../redux/banner/bannerSlice";
import { NavigationLink } from "../AppNavigation/AppNavigation";
import styles from "./App.module.scss";

const cx = classNames.bind(styles);

interface Props {
  onSignout: () => void;
}

const App: React.FC<Props> = ({ onSignout }) => {
  const { t } = useTranslation(["application"]);
  const showLanguagePage: boolean = process.env.NODE_ENV !== "production";

  const bannerOpen = useSelector(selectBannerOpen);
  const userSettingsLoaded = useSelector(selectSettingsLoaded);
  const privacyPolicyViewed = useSelector(selectPrivacyPolicyViewed);

  const dispatch = useDispatch();
  useEffect(() => {
    addApplicationInsights();
  }, []);

  useEffect(() => {
    if (!userSettingsLoaded) {
      return;
    }

    const bannerViewed = privacyPolicyViewed || localStorage.getItem("privacyPolicyViewed");
    if (!userInTeams() && !bannerViewed) {
      dispatch(setOpen({ open: true }));
    }
  }, [dispatch, privacyPolicyViewed, userSettingsLoaded]);

  useEffect(() => {
    dispatch(reqAccount());
    dispatch(reqProfile());
    dispatch(reqUserSettings());
  }, [dispatch]);

  const links = useMemo<NavigationLink[]>(() => {
    let validLinks = [
      {
        to: "/home",
        label: t("mainNavigation.home"),
      },
      {
        to: "/discover",
        label: t("mainNavigation.discover"),
      },
    ];

    if (!userInTeams()) {
      validLinks.push({
        to: "/groups",
        label: t("mainNavigation.groups"),
      });
    }

    return validLinks;
  }, [t]);

  return (
    <LdProvider>
      <BreakpointContextWrapper>
        {bannerOpen && <PrivacyPolicyBanner />}
        <div
          className={cx(styles.root, {
            [styles.pushed]: bannerOpen,
          })}
        >
          <AppHeader
            search={<AppSearch placeholder={t("search.placeholder")} />}
            account={<AccountMenu onSignout={onSignout} />}
            links={links}
          />
          <main role="main" id="main">
            <Routes>
              <Route path="/discover/*" element={<DiscoverPageRoutes />} />
              <Route path="/groups/*" element={<GroupPageRoutes />} />
              <Route path="/home" element={<HomePage />} />
              {showLanguagePage && <Route path="/lang" element={<AppLanguageSelector />} />}
              {/* NOTE: Put settings link item here */}
              <Route path="/profile/*" element={<Profile />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </main>
        </div>
        <NotificationProvider />
      </BreakpointContextWrapper>
    </LdProvider>
  );
};

export default App;
