import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RStatus } from "../../../Application/types";
import { UserSettings } from "../../types";

export interface UserSettingsState {
  status: RStatus;
  settings?: UserSettings;
  errorMessage: string | undefined;
}

export const initialState: UserSettingsState = {
  status: RStatus.Idle,
  errorMessage: undefined,
  settings: undefined,
};

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState: initialState,
  reducers: {
    req(state) {
      return { ...state, status: RStatus.Pending };
    },
    got(state, action: PayloadAction<{ settings: UserSettings }>) {
      const settings = action.payload.settings;
      state.status = RStatus.Got;
      state.settings = settings;
    },
    updateSettings(state, action: PayloadAction<{ settings: Partial<UserSettings> }>) {
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
    },
    err(state, action: PayloadAction<{ errorMessage: UserSettingsState["errorMessage"] }>) {
      state.status = RStatus.Error;
      state.errorMessage = action.payload.errorMessage;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const { req, got, updateSettings, err, reset } = userSettingsSlice.actions;

// reducers
export default userSettingsSlice.reducer;
