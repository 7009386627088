import classNames from "classnames/bind";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";

import { InvisibleButton } from "../../layout";
import { setOpen } from "../../redux/banner/bannerSlice";

import { ReactComponent as MyLogo } from "./browser_check.svg";

import { saveUserSettings } from "features/Account/redux/userSettings/userSettingsMiddleware";
import { updateSettings } from "features/Account/redux/userSettings/userSettingsSlice";
import { PRIVACY_POLICY_AGREEMENT_KEY } from "features/Account/types";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../../hooks/useBreakpoint";
import { PrivacyBannerBody } from "./BannerBody/PrivacyBannerBody";
import styles from "./PrivacyPolicyBanner.module.scss";

const cx = classNames.bind(styles);

export const PrivacyPolicyBanner: React.FC = () => {
  const { t, i18n } = useTranslation("application");
  const { isMobileScreen } = useBreakpoint();

  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [measurement, setMeasurement] = useState<DOMRect>();

  const wrapperNodeRef = useRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const resizeObs = useCallback(() => {
    setMeasurement(wrapperNodeRef.current!.getBoundingClientRect());
  }, []);

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      wrapperNodeRef.current = node;
      setMounted(true);
    }
  }, []);

  // Fixes issue with initial component measurement being inaccurate on some resolutions
  useEffect(() => {
    const callback = () => {
      setLoaded(true);
    };
    if (document.readyState === "complete") {
      // Handles the case where we're signing in for the first time
      callback();
      // Since we can't rely on window load event,
      // we run a slightly delayed measure
      // Worst case it that the end of the banner closing
      // jumps to completion rather than smoothly finishes
      const remeasureTimeout = setTimeout(() => {
        setMeasurement(wrapperNodeRef.current!.getBoundingClientRect());
      }, 2000);
      return () => clearTimeout(remeasureTimeout);
    } else {
      window.addEventListener("load", callback);
      return () => window.removeEventListener("load", callback);
    }
  }, []);

  // When component is mounted, have it move in from the top
  useEffect(() => {
    if (mounted && loaded) {
      wrapperNodeRef.current!.style.marginTop = `0px`;
      setMeasurement(wrapperNodeRef.current!.getBoundingClientRect());
      window.addEventListener("resize", resizeObs);
    }

    return () => {
      window.removeEventListener("resize", resizeObs);
    };
  }, [loaded, mounted, resizeObs]);

  const handleClose = useCallback(() => {
    wrapperNodeRef.current!.style.marginTop = `-${measurement?.height}px`;
    saveUserSettings(PRIVACY_POLICY_AGREEMENT_KEY, "true");
    dispatch(
      updateSettings({
        settings: {
          privacyPolicyViewed: true,
        },
      }),
    );
    // Remove from the DOM once it moves off screen
    const removeFromDom = (e: TransitionEvent) => {
      // JS_DOM doesn't properly support TransitionEvent,
      // so tests don't support the propertyName field
      if (e.propertyName === "margin-top" || process.env.NODE_ENV === "test") dispatch(setOpen({ open: false }));
    };
    wrapperNodeRef.current!.addEventListener("transitionend", removeFromDom);
    return () => wrapperNodeRef.current!.removeEventListener("transitionend", removeFromDom);
  }, [dispatch, measurement]);

  return (
    <div
      className={cx(styles.root, {
        [styles.vertical]: isMobileScreen,
      })}
      ref={ref}
      data-testid="privacy policy banner"
    >
      {!isMobileScreen && <MyLogo style={{ marginRight: 24 }} />}
      <p className={styles.body}>
        <PrivacyBannerBody language={i18n.language} />
      </p>
      <Button
        inverted
        content={t("common.gotIt")}
        className={cx(styles.actionButton, {
          [styles.fullWidth]: isMobileScreen,
        })}
        onClick={handleClose}
      />
      {!isMobileScreen && (
        <div style={{ marginLeft: 24 }}>
          <InvisibleButton onClick={handleClose}>
            <Icon name="times" className="thin" inverted size="big" />
          </InvisibleButton>
        </div>
      )}
    </div>
  );
};
