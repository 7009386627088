import { call, put, select, takeLatest } from "redux-saga/effects";

import { getApplicationsFilters, getPublishersFilters } from "features/Discover/config/filters";
import { fetchApplications } from "features/Discover/services/applicationsService";
import { fetchPublisherFilters } from "features/Discover/services/publishersService";
import { RStatus } from "../../../Application/types";
import { mapToDiscoveryItems } from "../../services/searchResultsMapperService";
import { fetchSearchResultsSearch } from "../../services/searchResultsService";
import { Application, DiscoveryItem, FilterSlice, SearchResult, Publisher } from "../../types";
import { getSearchResults, selectSearchFilters } from "./searchResultsSelectors";
import {
  err,
  fetchMore,
  got,
  gotFilters,
  initSearch,
  outOfResults,
  resetFilter,
  resetFilters,
  SearchResultsState,
  updateFilters,
  updateSort,
  updateStatus,
  updateTerm,
} from "./searchResultsSlice";

export function* handleFetchNewSearchResults() {
  try {
    const state: SearchResultsState = yield select(getSearchResults);
    const { activePage, search, top } = state;
    const { appliedFilters, sortType, term } = search;
    const skip = (activePage - 1) * top;
    const searchResults: SearchResult[] = yield call(
      fetchSearchResultsSearch,
      term as string,
      appliedFilters,
      sortType,
      skip,
      top,
    );

    const discoveryItems = mapToDiscoveryItems(searchResults);

    yield call(testForOutOfResults, discoveryItems, top);
    yield put(got({ items: discoveryItems }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* handleFetchMoreSearchResults() {
  try {
    const state: SearchResultsState = yield select(getSearchResults);
    const { activePage, search, top, reachedEnd, items } = state;
    const { appliedFilters, sortType, term } = search;
    if (reachedEnd && items) {
      yield put(updateStatus({ status: RStatus.Got }));
      return;
    }
    const skip = (activePage - 1) * top;
    const searchResults: SearchResult[] = yield call(
      fetchSearchResultsSearch,
      term as string,
      appliedFilters,
      sortType,
      skip,
      top,
    );

    const discoveryItems = mapToDiscoveryItems(searchResults);

    yield call(testForOutOfResults, discoveryItems, top);
    yield put(got({ items: items.concat(discoveryItems) }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* testForOutOfResults(searchResults: DiscoveryItem[], top: number) {
  if (searchResults.length < top) {
    yield put(outOfResults());
  }
}

export function* handleRequestFilterOptions() {
  try {
    const filters: FilterSlice = yield select(selectSearchFilters);
    const entityTypes = [...(filters.entityType ?? [])].map(x => x as string);
    const applications: Application[] = yield call(fetchApplications, entityTypes);
    const publishers: Publisher[] = yield call(fetchPublisherFilters, entityTypes);
    yield put(gotFilters({ key: "application", options: getApplicationsFilters(applications) }));
    yield put(gotFilters({ key: "publisher", options: getPublishersFilters(publishers) }));
  } catch (error: any) {
    yield put(err({ errorMessage: error?.message }));
  }
}

export function* searchResultsWatcherSaga() {
  yield takeLatest(fetchMore.type, handleFetchMoreSearchResults);
  yield takeLatest(
    [initSearch.type, updateSort.type, updateFilters.type, resetFilter.type, updateTerm.type, resetFilters.type],
    handleFetchNewSearchResults,
  );
  yield takeLatest([initSearch.type, updateFilters.type, resetFilters.type], handleRequestFilterOptions);
}
