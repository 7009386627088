import { AxiosError } from "axios";
import { call, debounce, put, takeLatest } from "redux-saga/effects";
import { fetchUserSettings, updateUserSettings } from "../../services/userSettingsService";
import { UserSettings } from "../../types";
import { err, got, req, updateSettings } from "./userSettingsSlice";

export function* handleFetchUserSettings() {
  try {
    const settings: UserSettings = yield call(fetchUserSettings);
    yield put(got({ settings }));
  } catch (error: any) {
    if (error?.code === AxiosError.ERR_BAD_REQUEST) {
      yield put(got({ settings: {} }));
    } else {
      yield put(err({ errorMessage: error?.message }));
    }
  }
}

export function* handleUpdateUserSettings(action: ReturnType<typeof updateSettings>) {
  const { settings } = action.payload;
  try {
    yield call(updateUserSettings, settings);
  } catch (error: any) {}
}

export function* userSettingsWatcherSaga() {
  yield takeLatest(req.type, handleFetchUserSettings);
  yield debounce(3000, updateSettings.type, handleUpdateUserSettings);
}
