import { Middleware } from "@reduxjs/toolkit";
import { UserSettings } from "../../types";
import { got, updateSettings } from "./userSettingsSlice";

const upsertLocalStorage = (settings: UserSettings) => {
  for (const key of Object.keys(settings) as Array<keyof UserSettings>) {
    saveUserSettings(key, settings[key]?.toString());
  }
};

export const userSettingsMiddleware: Middleware = _ => next => action => {
  const result = next(action);

  if (got.match(action) || updateSettings.match(action)) {
    const settings: UserSettings = action.payload.settings;
    upsertLocalStorage(settings);
  }

  return result;
};

export const saveUserSettings = (key: keyof UserSettings, value?: string) => {
  if (value) {
    localStorage.setItem(key, value);
  }
};

export const getUserSetting = (key: keyof UserSettings) => localStorage.getItem(key);
