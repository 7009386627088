import { z } from "zod";
import { accountSchema, mainContactSchema, myDataSchema, profileSchema, userSettings } from "./schemas";

export const PRIVACY_POLICY_AGREEMENT_KEY = "privacyPolicyViewed";

export type Account = z.infer<typeof accountSchema>;
export type Profile = z.infer<typeof profileSchema>;
export type MyData = z.infer<typeof myDataSchema>;
export type MainContact = z.infer<typeof mainContactSchema>;
export type UserSettings = z.infer<typeof userSettings>;
