import videojs from "video.js";
import { removeClassIfElementExists } from "../../../VideoPlayer/utils/vjsHelpers";
import QualityLevelsMenu from "./QualityLevelsMenu";
import { QualityLevels } from "./qualityLevelsPlugin";

const MenuItem = videojs.getComponent("MenuItem");

export interface QualityLevel {
  index: number;
  bitrate: number;
  enabled: boolean;
  height: number;
  width: number;
  label?: string;
  sortVal: number;
  controller?: Partial<QualityLevelsMenu>;
}

class QualityLevelsItem extends MenuItem {
  constructor(player: any, options: Object) {
    super(player, options);
    // @ts-ignore
    this.selectable = true;
    this.update();
  }

  handleClick(_: any): void {
    // Remove open class when selecting a quality option
    removeClassIfElementExists(document.getElementsByClassName("open")[0], "open");
    this.onSelected();
  }

  onSelected() {
    const player = this.player() as any;
    const qualityLevels = player.qualityLevels() as QualityLevels;

    for (let i = 0; i < qualityLevels.length; i++) {
      if ((this.options_ as QualityLevel).index === i) {
        qualityLevels[i].enabled = true;
      } else {
        qualityLevels[i].enabled = false;
      }
    }
    const selectedIndex = (this.options_ as QualityLevel).index;
    qualityLevels.selectedIndex_ = selectedIndex;
    qualityLevels.trigger({
      type: "change",
      selectedIndex: selectedIndex,
    });
    player.trigger("qualitychange", { quality: qualityLevels[selectedIndex].height });

    // @ts-ignore
    this.options_.controller.triggerItemUpdate();
    this.update();
  }

  update() {
    const qualityLevels = (this.player() as any).qualityLevels() as QualityLevels;
    (this as any).selected((this.options_ as QualityLevel).index === qualityLevels.selectedIndex_);
  }
}

export default QualityLevelsItem;
